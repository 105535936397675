import i18next from 'i18next';

i18next.init({
  fallbackLng: 'lt_en',
  resources: {
    by: {
      translations: require('../locales/by/translation.json')
    },
    cy_en: {
      translations: require('../locales/cy_en/translation.json')
    },
    am: {
      translations: require('../locales/am/translation.json')
    },
    en_am: {
      translations: require('../locales/en_am/translation.json')
    },
    ru: {
      translations: require('../locales/ru/translation.json')
    },
    ua: {
      translations: require('../locales/ua/translation.json')
    },
    lt_lt: {
      translations: require('../locales/lt_lt/translation.json')
    },
    lt_en: {
      translations: require('../locales/lt_en/translation.json')
    },
    eu_en: {
      translations: require('../locales/lt_en/translation.json')
    },
    su_ru: {
      translations: require('../locales/ru/translation.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  loader: false
});

i18next.languages = ['by', 'am', 'en_am', 'ru', 'ua', 'lt_en','lt_lt', 'su_ru', 'eu_en'];

export default i18next;
