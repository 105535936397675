import React from "react";
import { Helmet } from "react-helmet";

const Wrapper = ({ element }) => {
  return (
  <>
    <Helmet>
      <script type="text/javascript" defer async>{`
        (function() {
          document.umnicoWidgetHash = '${process.env.GATSBY_UMNICO_ID}';
          const x = document.createElement('script');
          x.src = 'https://umnico.com/assets/widget-loader.js';
          x.type = 'text/javascript';
          x.charset = 'UTF-8';
          x.async = true;
          document.body.appendChild(x);
       })();`}
      </script>
    </Helmet>
    {element}
  </>
  )
};
export default Wrapper;